import {type ClientThunkAction} from "@app/client-store";
import {callWorkerFn} from "@app/worker";
import {FORMULA_EDITOR_INPUT_CLASS} from "@features/shared-views/FormulaTextEditor";
import {runErrorCheckingForDatasourceDiff} from "@shared/lib/alert-utilities";
import {
  getChangesForInsertFormulaForDates,
  getDatasourcesForMonth,
  getEmptyDatasourceDiff,
  mergeDatasourceDiffObjects,
} from "@shared/lib/datasource-utilities";
import {addDeltaMonthsToDateKey, getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {log} from "@shared/lib/debug-provider";
import {getUniqueSlug} from "@shared/lib/slug";
import {getVisibleFlatOrdering} from "@shared/lib/template-utilities";
import {fireActionInWorker} from "@shared/worker-fn-gateway";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {toggleDepartmentsExpanded, toggleVendorsExpanded} from "@state/global/slice";
import {selectAllTemplateRows, upsertRow, upsertRows} from "@state/template-rows/slice";
import {monthlyCacheUpdated} from "@state/transaction-items/slice";
import {projKeyOmitNulls} from "@state/utils";
import {startTransition} from "react";

import {FORMULA_BAR_ID} from "../FormulaBar";
import selectTemplateUiData from "./select-template-ui-data";
import {
  selectIsCellDisabled,
  selectSelectedRow,
  selectSelectedTemplate,
  selectUiDatasourceForSelectedCell,
  selectors,
} from "./selectors";
import {setTemplatesViewState, updateTemplatesViewState} from "./slice";
import {getNewHighlightCursorCell} from "./utils";

import type {ClientThunkDispatchWithExtraArg} from "@app/client-store";
import type {ActionCreator} from "@reduxjs/toolkit";
import type {TemplateRow} from "@shared/types/db";

import {optimisticUpdate} from "@/lib/optimistic-update";
import {logUserEvent} from "@app/websockets/websocket-action-logger";

export const clearFormulaForSelectedCells: ActionCreator<ClientThunkAction> = () => async (dispatch, getState) => {
  const state = getState();
  const {rowId, column, departmentId, vendor} = state.templatesView.activeCell;
  const highlightedDateKeys = selectors.highlightedDateKeys(state);
  const scenarioId = state.global.scenarioId;
  const template = selectSelectedTemplate(state);

  const dateKeys = highlightedDateKeys?.length ? highlightedDateKeys : [column];

  const row = state.templateRows.entities[rowId || ""];
  if (!row || !rowId) return;
  if (column === "name") {
    const rowIdsByName = state.templateRows.idsByName;
    const newSlug = getUniqueSlug("", rowIdsByName, row.name).slug;

    dispatch(upsertRow({...row, display_name: "", name: newSlug}));
  } else if (column && row && scenarioId && template) {
    const dateKeyToFormulaMapping: Record<string, string | null> = {};
    const currentFormulaMapping: Record<string, string | null> = {};
    for (const dateKey of dateKeys) {
      if (dateKey) dateKeyToFormulaMapping[dateKey] = null;
      if (dateKey) {
        const datasource = getDatasourcesForMonth(
          state.datasources,
          {
            rowId,
            scenarioId,
            dateKey,
            departmentId: departmentId ?? null,
            vendor: vendor ?? null,
          },
          template.options,
        )[0];
        if (datasource?.type === "formula" || datasource?.type === "hiring-plan-formula") {
          currentFormulaMapping[dateKey] = datasource.options.formula ?? null;
        }
      }
    }

    // Log the user event
    logUserEvent("clear-formula-for-selected-cells", {
      rowId,
      departmentId,
      vendor,
      clearedFormulas: currentFormulaMapping,
    });

    const datasourceDiff = getChangesForInsertFormulaForDates({
      row,
      scenarioId,
      dateKeyToFormulaMapping,
      state,
      departmentId,
      vendor,
    });

    // Check for ref and formula errors, and update the state accordingly
    runErrorCheckingForDatasourceDiff(datasourceDiff, state, dispatch);

    await optimisticUpdate({
      scenarioId,
      dispatch,
      datasourceDiff,
    });

    // Save datasources and trigger compute
    await dispatch(
      applyDatasourceChanges({
        datasourceDiff,
        reason: `Clear formula for ${column} in row ${row.name}`,
      }),
    );
  }
};

function focusFormulaBar(dispatch: ClientThunkDispatchWithExtraArg) {
  // Get input element inside formula bar (using FORMULA_BAR_ID and FORMULA_EDITOR_INPUT_CLASS)
  const inputElement = document.querySelector<HTMLInputElement>(
    `#${FORMULA_BAR_ID} input.${FORMULA_EDITOR_INPUT_CLASS}`,
  );

  if (!inputElement) return;

  // Focus the input element if it's not already focused
  if (document.activeElement !== inputElement) {
    dispatch(enableEditing("formulaBar"));
  }
}

export const enableEditing: ActionCreator<ClientThunkAction> =
  (component?: "formulaBar" | "cell", formulaOverride?: string) => (dispatch, getState) => {
    const state = getState();
    const disabled = selectIsCellDisabled(state);
    const template = selectSelectedTemplate(state);
    const {rowId, column, departmentId, vendor} = state.templatesView.activeCell || {};
    const currentCellBeingEdited = state.templatesView.cellBeingEdited;

    if (disabled) return;

    // If the cell being edited is this one already, then only change the component if it's different
    if (
      currentCellBeingEdited &&
      currentCellBeingEdited.rowId === rowId &&
      currentCellBeingEdited.dateKey === column &&
      (currentCellBeingEdited.departmentId ?? null) === (departmentId ?? null) &&
      (currentCellBeingEdited.vendor ?? null) === (vendor ?? null)
    ) {
      if (component && currentCellBeingEdited.component !== component) {
        dispatch(
          updateTemplatesViewState({
            cellBeingEdited: {
              ...currentCellBeingEdited,
              component,
            },
            caller: `thunks.enableEditing -> same cell being edited, different component`,
          }),
        );
        focusFormulaBar(dispatch);
      }
      return;
    }

    if (column === "name" && rowId && !state.templatesView.cellBeingEdited && template?.type === "generic") {
      const row = state.templateRows.entities[rowId];
      if (!row) return;
      const editedName = typeof formulaOverride !== "undefined" ? formulaOverride : row.display_name;
      dispatch(
        updateTemplatesViewState({
          cellBeingEdited: {
            rowId,
            dateKey: column,
            component: component || "cell",
            formula: editedName,
            originalFormula: editedName,
          },
          caller: `thunks.enableEditing -> name cell branch`,
        }),
      );
      focusFormulaBar(dispatch);
    } else {
      if (!disabled && !!rowId && !!column && !!template && state.global.scenarioId) {
        let formula = formulaOverride;
        if (typeof formulaOverride === "undefined") {
          const datasource = getDatasourcesForMonth(
            state.datasources,
            {
              rowId,
              scenarioId: state.global.scenarioId,
              dateKey: column,
              departmentId: departmentId ?? null,
              vendor: vendor ?? null,
            },
            template.options,
          )[0];

          formula =
            datasource?.type === "formula" || datasource?.type === "hiring-plan-formula"
              ? (datasource.options.formula ?? undefined)
              : undefined;
        }
        dispatch(
          updateTemplatesViewState({
            cellBeingEdited: {
              rowId,
              vendor,
              departmentId,
              dateKey: column,
              component: component || "cell",
              formula: formula || "",
              originalFormula: formula || "",
            },
            caller: `thunks.enableEditing -> non-name cell branch`,
          }),
        );
        focusFormulaBar(dispatch);
      }
    }
  };

export const moveSelectedCell: ActionCreator<ClientThunkAction> =
  (direction: "right" | "left" | "up" | "down") => (dispatch, getState) => {
    const state = getState();
    const selectedRow = selectSelectedRow(state);
    const template = selectSelectedTemplate(state);
    const {activeCell, collapsedRows: collapsedEntityIds} = state.templatesView;

    if (!selectedRow || !activeCell.column || !template) return;
    if (["right", "left"].includes(direction)) {
      let newCol = activeCell.column;
      if (activeCell.column === "name" && direction === "right") {
        newCol = template.options.visibleStart;
      } else if (activeCell.column === template.options.visibleStart && direction === "left") {
        newCol = "name";
      } else {
        const resolvedNewCol = addDeltaMonthsToDateKey(activeCell.column, direction === "right" ? 1 : -1);
        if (resolvedNewCol >= template.options.visibleStart && resolvedNewCol <= template.options.visibleEnd)
          newCol = resolvedNewCol;
      }
      dispatch(
        updateTemplatesViewState({
          activeCell: {...activeCell, column: newCol},
          caller: `moveSelectedCell -> ${direction}`,
        }),
      );
    } else {
      const rowsList = getVisibleFlatOrdering(template.ordering, collapsedEntityIds, state.templateRows.entities);
      const selectedRowIndex = rowsList.indexOf(selectedRow.id);

      if (
        (direction === "up" && selectedRowIndex === 0) ||
        (direction === "down" && selectedRowIndex === rowsList.length - 1)
      )
        return;
      const newSelectedRowId = rowsList[selectedRowIndex + (direction === "up" ? -1 : 1)];

      dispatch(
        updateTemplatesViewState({
          activeCell: {...activeCell, rowId: newSelectedRowId},
          cellBeingEdited: null,
          caller: `moveSelectedCell -> ${direction}`,
        }),
      );
    }
  };

/**
 * This function is called when the user hits ctrl+shift+right or ctrl+shift+left
 * It adds multiple cells to the selection, mirroring the behavior of excel
 *
 * Rules:
 * The behavior depends on the last selected cell. This is either the last highlighted date key or the active cell.
 * - If the last selected cell is empty and the next cell is also empty, it will select all empty cells until it finds a non-empty cell. It will also add that non-empty cell to the selection
 * - If the last selected cell is empty and the next cell is non-empty, it will only add that next cell to the selection
 * - If the last selected cell is non-empty and the next cell is empty, it will select all empty cells until it finds a non-empty cell. It will also add that non-empty cell to the selection
 * - If the last selected cell is non-empty and the next cell is non-empty, it will select all non-empty cells until it finds an empty cell. It will NOT add that empty cell to the selection
 *
 * @param direction - The direction in which the user wants to add cells to the selection
 * @returns {void}
 *
 */
export const addMultipleCellsToSelection: ActionCreator<ClientThunkAction> =
  (direction: "right" | "left") => (dispatch, getState) => {
    const state = getState();
    const {activeCell, highlightedDateKeys} = state.templatesView;
    const template = selectSelectedTemplate(state);
    if (!activeCell.column || !template || activeCell.column === "name") return;

    const templateDisplayData = selectTemplateUiData(state, template?.id ?? "").rowsForDisplay;
    const cellsForRow =
      templateDisplayData.find((rowForDisplay) => rowForDisplay.row.id === activeCell.rowId)?.cells ?? [];
    const valuesForRow: (string | number | null)[] = cellsForRow.map((cell) => cell.value).slice(1);
    const dateKeysForRow: string[] = cellsForRow.map((cell) => cell.dateKey).slice(1);

    let newHighlightedDateKeys: string[] = highlightedDateKeys?.length ? [...highlightedDateKeys] : [activeCell.column];

    let highlightDirection: "right" | "left";

    if (newHighlightedDateKeys.length === 1) {
      highlightDirection = direction;
    } else if (newHighlightedDateKeys[1] > activeCell.column) {
      highlightDirection = "right";
    } else if (newHighlightedDateKeys[0] < activeCell.column) {
      highlightDirection = "left";
    } else {
      highlightDirection = direction;
    }

    const currentHighlightCursorCell =
      highlightDirection === "right" ? (newHighlightedDateKeys.at(-1) ?? activeCell.column) : newHighlightedDateKeys[0];

    const newHighlightCursorCell = getNewHighlightCursorCell(
      currentHighlightCursorCell,
      direction,
      highlightDirection,
      dateKeysForRow,
      valuesForRow,
    );

    // If newHighlightCursorCell is the same as active column, mark the highlightedDateKeys as empty
    if (newHighlightCursorCell === activeCell.column) {
      newHighlightedDateKeys = [];
    } else if (newHighlightCursorCell > activeCell.column) {
      newHighlightedDateKeys = getAllDateKeysBetween(activeCell.column, newHighlightCursorCell);
    } else {
      newHighlightedDateKeys = getAllDateKeysBetween(newHighlightCursorCell, activeCell.column);
    }

    // Only update the highlightedDateKeys if it's different from the current one
    if (
      newHighlightedDateKeys.length !== highlightedDateKeys?.length ||
      newHighlightedDateKeys[0] !== highlightedDateKeys[0] ||
      newHighlightedDateKeys.at(-1) !== highlightedDateKeys.at(-1)
    ) {
      dispatch(
        updateTemplatesViewState({
          highlightedDateKeys: newHighlightedDateKeys,
          caller: `addMultipleCellsToSelection -> ${direction}`,
        }),
      );
    }
  };

export const expandSelectionByOne: ActionCreator<ClientThunkAction> =
  (direction: "right" | "left") => (dispatch, getState) => {
    const state = getState();
    const {activeCell, highlightedDateKeys} = state.templatesView;
    const template = selectSelectedTemplate(state);
    if (!activeCell.column || !template) return;
    if (direction === "right") {
      const mode = !highlightedDateKeys.length || highlightedDateKeys[0] === activeCell.column ? "add" : "subtract";
      const lastSelected = highlightedDateKeys.at(-1);
      if (lastSelected !== template.options.visibleEnd) {
        const newDateKeysSelected = getAllDateKeysBetween(
          mode === "add" ? activeCell.column : addDeltaMonthsToDateKey(highlightedDateKeys[0], 1),
          mode === "add" ? addDeltaMonthsToDateKey(lastSelected || activeCell.column, 1) : activeCell.column,
        );
        dispatch(
          updateTemplatesViewState({
            highlightedDateKeys: newDateKeysSelected.length === 1 ? [] : newDateKeysSelected,
            caller: `expandSelectionByOne -> ${direction}`,
          }),
        );
      }
    } else {
      if (!highlightedDateKeys.length) {
        dispatch(
          updateTemplatesViewState({
            highlightedDateKeys: [addDeltaMonthsToDateKey(activeCell.column, -1), activeCell.column],
            caller: `expandSelectionByOne -> ${direction}`,
          }),
        );
      } else {
        const mode = highlightedDateKeys.at(-1) === activeCell.column ? "add" : "subtract";
        const firstSelected = highlightedDateKeys[0] || activeCell.column;
        if (firstSelected !== template.options.visibleStart) {
          const newDateKeysSelected = getAllDateKeysBetween(
            mode === "add" ? addDeltaMonthsToDateKey(firstSelected, -1) : activeCell.column,
            mode === "add" ? activeCell.column : addDeltaMonthsToDateKey(highlightedDateKeys.at(-1)!, -1),
          );
          dispatch(
            updateTemplatesViewState({
              highlightedDateKeys: newDateKeysSelected,
              caller: `expandSelectionByOne -> ${direction}`,
            }),
          );
        }
      }
    }
  };

export const setPastedValuesToCells: ActionCreator<ClientThunkAction> =
  (values: (number | string | null)[][]) => async (dispatch, getState) => {
    const state = getState();
    const {
      activeCell: {column, departmentId, vendor},
    } = state.templatesView;
    const scenarioId = state.global.scenarioId;
    const template = selectSelectedTemplate(state);
    let rowCursor = selectSelectedRow(state);
    const rows = selectAllTemplateRows(state);
    const rowNames = rows.map((row) => row.name);
    if (!column || !template || !scenarioId || !rowCursor) return;

    // if this is not just a generic worksheet, don't support multi rows for now
    if (template.type !== "generic") {
      values = values.slice(0, 1);
    }

    let datasourceDiff = getEmptyDatasourceDiff();
    let nbValues = 0;
    const rowsToUpsert: TemplateRow[] = [];

    // Check if there's only one value AND there are highlighted cells.
    // If so, duplicate that value to match the number of highlighted cells so that it can be pasted into all of them
    if (values.length === 1 && values[0].length === 1 && state.templatesView.highlightedDateKeys.length > 1) {
      const newValuesArray: (number | string | null)[] = [];
      for (let i = 0; i < state.templatesView.highlightedDateKeys.length; i++) {
        newValuesArray.push(values[0][0]);
      }
      values = [newValuesArray];
    }

    for (const rowValues of values) {
      // Update formula in datasources first
      if (!rowCursor) break;
      const dateKeyToFormulaMapping: Record<string, string | null> = {};

      let dateKeyCursor = column;

      for (const value of rowValues) {
        if (dateKeyCursor === "name") {
          const stringName = value?.toString() ?? "";
          const newSlug = getUniqueSlug(stringName, rowNames, rowCursor.name).slug;
          if (newSlug !== rowCursor.name) rowsToUpsert.push({...rowCursor, display_name: stringName, name: newSlug});
        } else {
          dateKeyToFormulaMapping[dateKeyCursor] = value?.toString() ?? null;
        }
        nbValues++;
        dateKeyCursor =
          dateKeyCursor === "name" ? template.options.visibleStart : addDeltaMonthsToDateKey(dateKeyCursor, 1);
        if (dateKeyCursor > template.options.visibleEnd) break;
      }

      datasourceDiff = mergeDatasourceDiffObjects(
        datasourceDiff,
        getChangesForInsertFormulaForDates({
          row: rowCursor,
          scenarioId,
          dateKeyToFormulaMapping,
          state,
          departmentId,
          vendor,
        }),
      );

      // Update rowIdCursor based on templateOrdering
      const rowsList = getVisibleFlatOrdering(template.ordering, [], state.templateRows.entities);
      const indexOfCurrentRow = rowsList.indexOf(rowCursor.id);
      if (indexOfCurrentRow === rowsList.length - 1) break;
      rowCursor = state.templateRows.entities[rowsList[indexOfCurrentRow + 1]] ?? null;
    }

    dispatch(upsertRows(rowsToUpsert));

    await optimisticUpdate({
      scenarioId,
      dispatch,
      datasourceDiff,
    });

    // Save datasources and trigger compute
    await dispatch(
      applyDatasourceChanges({
        datasourceDiff,
        reason: `Apply pasted values to ${nbValues} cells in template ${template.name}`,
      }),
    );
  };

export const applyFormulaToCells: ActionCreator<ClientThunkAction> =
  (type: "dragged" | "highlighted") => async (dispatch, getState) => {
    const state = getState();
    const {
      activeCell: {column, departmentId, vendor},
      highlightedDateKeys,
      draggingOverCols,
    } = state.templatesView;
    const scenarioId = state.global.scenarioId;
    const template = selectSelectedTemplate(state);
    const row = selectSelectedRow(state);
    if (!column || !template || !scenarioId || !row) return;

    const dateKeys = type === "dragged" ? draggingOverCols : highlightedDateKeys;

    if (dateKeys.length) {
      const {type: dataType, value: datasource} = selectUiDatasourceForSelectedCell(state) || {};

      if (dataType !== "range" || datasource?.type === "integration") return;
      const formula = datasource ? datasource.options.formula : null;

      // Log the user event
      const existingFormulaMapping: Record<string, string | null> = {};
      for (const dateKey of dateKeys) {
        if (dateKey) {
          const datasource = getDatasourcesForMonth(
            state.datasources,
            {
              rowId: row.id,
              scenarioId,
              dateKey,
              departmentId: departmentId ?? null,
              vendor: vendor ?? null,
            },
            template.options,
          )[0];
          if (datasource?.type === "formula" || datasource?.type === "hiring-plan-formula") {
            existingFormulaMapping[dateKey] = datasource.options.formula ?? null;
          }
        }
      }

      logUserEvent("apply-formula-to-selected-cells", {
        rowId: row.id,
        departmentId,
        vendor,
        highlightedDateKeys: dateKeys,
        existingFormulas: existingFormulaMapping,
        newFormula: formula,
        type: type === "dragged" ? "dragged" : "highlighted + shortcut",
      });

      // Update formula in datasources first
      const dateKeyToFormulaMapping = Object.fromEntries(dateKeys.map((dateKey) => [dateKey.slice(0, 7), formula]));

      const datasourceDiff = getChangesForInsertFormulaForDates({
        row,
        scenarioId,
        dateKeyToFormulaMapping,
        state,
        departmentId,
        vendor,
      });

      log("applyFormulaToSelectedCells", {
        updatedDatasources: datasourceDiff.upserts ?? [],
        deletes: datasourceDiff.deletes ?? [],
      });

      // Check for ref and formula errors, and update the state accordingly
      runErrorCheckingForDatasourceDiff(datasourceDiff, state, dispatch);

      await optimisticUpdate({
        scenarioId,
        dispatch,
        datasourceDiff,
      });

      // Save datasources and trigger compute
      await dispatch(
        applyDatasourceChanges({
          datasourceDiff,
          reason: `Apply formula to ${highlightedDateKeys.length} cells in row ${row.name}`,
        }),
      );
    }

    dispatch(setTemplatesViewState({key: type === "dragged" ? "draggingOverCols" : "highlightedDateKeys", value: []}));
  };

export const toggleExpanded: ActionCreator<ClientThunkAction> =
  ({rowId, departmentId, forceState}: {rowId: string; departmentId: string | string[] | null; forceState?: boolean}) =>
  (dispatch, getState) => {
    const state = getState();

    const row = state.templateRows.entities[rowId];
    if (!row) return;

    let combinationsToggle: {rowId: string; departmentId: string[]; forceState?: boolean} = {
      rowId,
      departmentId: [],
      forceState,
    };
    const expandedRows = state.global.departmentsExpandedRowIds;
    const expandedDepartments = state.global.expandedVendors;

    let rowExpanded = expandedRows.includes(rowId);
    for (const deptId of Array.isArray(departmentId) ? departmentId : departmentId ? [departmentId] : []) {
      if (departmentId) rowExpanded &&= expandedDepartments[rowId]?.includes(deptId);
      if (rowExpanded) {
        combinationsToggle.departmentId.push(deptId);
      }
    }

    if (rowExpanded) {
      dispatch(
        departmentId ? toggleVendorsExpanded(combinationsToggle) : toggleDepartmentsExpanded({rowId, forceState}),
      );
    } else {
      fireActionInWorker(
        departmentId ? toggleVendorsExpanded(combinationsToggle) : toggleDepartmentsExpanded({rowId, forceState}),
      );
      dispatch(loadDepartmentOrVendorData({rowId, departmentId}));
    }
  };

const latestRequestTimestamps: Record<string, number> = {};

export const loadDepartmentOrVendorData: ActionCreator<ClientThunkAction> =
  ({rowId, departmentId}: {rowId: string; departmentId: string | string[] | null}) =>
  (dispatch, getState) => {
    const state = getState();

    const row = state.templateRows.entities[rowId];
    if (!row) return;

    const scenarioId = state.global.scenarioId;
    const departmentIdsAsArray = Array.isArray(departmentId) ? departmentId : departmentId ? [departmentId] : [];

    const blueRowsKeys: string[] = [];
    const departmentIdsPulled = Array.isArray(departmentId) ? departmentId : departmentId ? [departmentId] : [null];
    for (const departmentIdPulled of departmentIdsPulled) {
      const blueRowsKey = projKeyOmitNulls(rowId, departmentIdPulled, scenarioId);
      blueRowsKeys.push(blueRowsKey);
    }

    // Generate a unique timestamp for this request and save it
    const currentTimestamp = Date.now();
    latestRequestTimestamps[rowId] = currentTimestamp;

    // If blueRowsPulledFromWorker includes all the blueRowsKeys, call toggleExpandedDepartment and if needed toggleExpandedVendor right away
    // const blueRowsPulledFromWorker = state.templatesView.blueRowsPulledFromWorker;
    // const allBlueRowsPulled = blueRowsKeys.every((blueRowsKey) => blueRowsPulledFromWorker.includes(blueRowsKey));

    // if (allBlueRowsPulled) {
    dispatch(toggleDepartmentsExpanded({rowId: row.id, forceState: true}));

    if (departmentId) {
      dispatch({
        ...toggleVendorsExpanded({
          rowId: row.id,
          departmentId: departmentIdsAsArray,
          forceState: true,
        }),
        meta: {isThreadSync: true},
      });
    }
    // }

    startTransition(() => {
      console.time("Worker call to getRowDepartmentPartialCache");
      requestIdleCallback(() => {
        callWorkerFn("getRowDepartmentPartialCache", [
          row?.mirror_of ? row.mirror_of : row?.id || "",
          departmentId ?? null,
          scenarioId ?? "",
        ]).then((cache) => {
          console.timeEnd("Worker call to getRowDepartmentPartialCache");

          // Check if the current request is still the latest one
          if (currentTimestamp !== latestRequestTimestamps[rowId]) {
            console.log("Discarding outdated worker response.");
            return;
          }

          if (cache) {
            console.time("Handling of the cache update received from the worker");
            console.log(`Cache update size: ${Object.keys(cache).length}`);
            for (const departmentIdPulled of departmentIdsAsArray) {
              const blueRowsPulledKey = projKeyOmitNulls(rowId, departmentIdPulled, scenarioId);
              if (!state.templatesView.blueRowsPulledFromWorker.includes(blueRowsPulledKey)) {
                state.templatesView.blueRowsPulledFromWorker.push(blueRowsPulledKey);
              }
            }
            dispatch(toggleDepartmentsExpanded({rowId: row.id, forceState: true}));
            if (departmentId) {
              dispatch(
                toggleVendorsExpanded({
                  rowId: row.id,
                  departmentId: departmentIdsAsArray,
                  forceState: true,
                }),
              );
            }

            dispatch(
              monthlyCacheUpdated({
                valuesByRowIdDateKey: {...state.transactionItems.valuesByRowIdDateKey, ...cache},
              }),
            );
            console.timeEnd("Handling of the cache update received from the worker");
          }
        });
      });
    });
  };
