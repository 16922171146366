import {store} from "@app/client-store";
import {isCellDisabled} from "@shared/lib/templates-utilities";

import type {TemplateRow} from "@shared/types/db";

export function isCellDisabledClient(row: TemplateRow | null, dateKey: string | null): boolean {
  const state = store.getState();
  return isCellDisabled(row, dateKey, state);
}

type GridData = (number | string | null)[][]; // Updated type to allow strings

function cleanValue(value: string): string {
  // Remove dollar signs, percentage, etc. You can extend this as needed
  return value.replace(/[\$\%\£\€]/gi, "").trim();
}

export function handlePastedData(event: React.ClipboardEvent<HTMLInputElement> | ClipboardEvent): {
  values: GridData;
  clipboardData: string;
} {
  const state = store.getState();
  const activeCell = state.templatesView.activeCell;

  // Get clipboard data as text
  const clipboardData = event.clipboardData?.getData("Text");
  if (!clipboardData) return {values: [], clipboardData: ""};

  // Split the clipboard data by rows and columns (assumes tab and newline delimited data)
  const rows = clipboardData.split("\n").filter((row) => row.trim() !== "");

  // Initialize grid data
  const gridData: GridData = [];

  for (const row of rows) {
    const cells = row.split("\t");
    const processedRow: (number | string | null)[] = []; // Updated type

    for (const [i, cell] of cells.entries()) {
      // Clean the value
      let cleanedValue = cleanValue(cell);

      // Check to see if the cleaned value matches a number with a regex
      // Check if it has any character other than comma,dot,space or number
      let hasInvalidCharacters = /[^0-9,. ]/.test(cleanedValue);

      // Handle a special case for accounting negative numbers (e.g. (123) => -123)
      // Check if the cleanedValue starts with a "(" and ends with a ")", and in between only has numbers, commas, dots or spaces
      if (/^\([0-9,. ]+\)$/.test(cleanedValue)) {
        cleanedValue = "-" + cleanedValue.slice(1, -1);
        hasInvalidCharacters = false;
      }

      if (hasInvalidCharacters || activeCell?.column === "name") {
        // If it has invalid characters, keep the original string value
        processedRow.push(cell);
      } else {
        // Remove any comma or space used as thousand separators
        cleanedValue = cleanedValue.replace(/[, ]/g, "");

        // Check if the sanitized value is a number
        if (!isNaN(Number(cleanedValue))) {
          processedRow.push(Number(cleanedValue));
        } else {
          // If the value is not a number, keep the original string value
          processedRow.push(cleanedValue);
        }
      }
    }

    gridData.push(processedRow);
  }

  return {values: gridData, clipboardData};
}
