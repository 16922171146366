import {createSelector, nanoid} from "@reduxjs/toolkit";
import {isDateKeyInRange} from "@shared/lib/date-utilities";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {generateClientSelectors} from "@shared/lib/misc";
import {isCellDisabled} from "@shared/lib/templates-utilities";
import {
  selectors as globalSelectors,
  selectDepartmentId,
  selectScenarioId,
  selectVersionLocked,
} from "@shared/state/global/slice";
import {selectTemplates} from "@shared/state/templates/slice";
import {selectFlattenedDepartments} from "@state/departments/selectors";
import {selectFlatTemplateOrdering} from "@state/templates/selectors";
import {projKeyOmitNulls} from "@state/utils";

import {initialState} from "./initial-state";

import type {ClientRootState} from "@app/client-store";
import type {RootState} from "@shared/state/store";
import type {Datasource} from "@shared/types/datasources";
import type {DepartmentWithDepth} from "@state/departments/selectors";
import {selectRowIdToVendorsMapping} from "@state/integrations/slice";
import type {FlattenedOrderingItem} from "@state/templates/selectors";

export const selectors = generateClientSelectors("templatesView", initialState);

export const selectTemplatesView = (state: ClientRootState) => state.templatesView;

export const selectSelectedRow = createSelector(
  (state: RootState) => state.templateRows,
  selectors.activeCell,
  (templatesViewState, activeCell) => templatesViewState.entities[activeCell.rowId ?? ""] || null,
);

export const selectSelectedTemplate = createSelector(
  selectTemplates,
  selectors.templateId,
  (templatesState, templateId) => templatesState.entities[templateId ?? ""] || null,
);

/**
 * Selects UI datasource for any given cell based on specific parameters
 *
 * @param state - The root state
 * @param rowId - The ID of the row
 * @param column - The column identifier
 * @param departmentId - The department ID
 * @param vendor - The vendor identifier
 * @returns A datasource object for the cell or null if not found
 */
export const selectUiDatasource = createSelector(
  [
    (state: RootState) => state.templateRows.entities,
    (state: RootState) => state.datasources,
    selectScenarioId,
    selectSelectedTemplate,
    (_state: RootState, rowId: string) => rowId,
    (_state: RootState, _rowId: string, column: string) => column,
    (_state: RootState, _rowId: string, _column: string, departmentId?: string | null) => departmentId,
    (_state: RootState, _rowId: string, _column: string, _departmentId?: string | null, vendor?: string | null) =>
      vendor,
  ],
  (
    rowEntities,
    datasourcesState,
    scenarioId,
    template,
    rowId,
    column,
    departmentId,
    vendor,
  ): {type: "text"; value: string} | {type: "range"; value: Datasource} | null => {
    const row = rowEntities[rowId];

    if (!row) return null;
    if (column === "name") return {type: "text", value: row.display_name};

    const datasources = datasourcesState.idsByProjKey[projKeyOmitNulls(rowId, scenarioId, departmentId, vendor)];

    if (!datasources?.length) return null;

    const matchingDatasources = mapEntitiesToIds(datasourcesState.entities, datasources);

    const templateOptions = template?.options;

    if (!scenarioId || !column || !templateOptions) return null;

    const matchingDatasource =
      matchingDatasources.find((datasource) => column && isDateKeyInRange(column, datasource, templateOptions)) ?? "";

    return !matchingDatasource ? null : {type: "range", value: matchingDatasource};
  },
);

/**
 * Selects UI datasource for the currently selected cell
 *
 * @param state - The root state
 * @returns A datasource object for the selected cell or null if not found
 */
export const selectUiDatasourceForSelectedCell = createSelector(
  [(state: ClientRootState) => state, selectors.activeCell],
  (state, activeCell) => {
    return selectUiDatasource(
      state,
      activeCell.rowId ?? "",
      activeCell.column ?? "",
      activeCell.departmentId,
      activeCell.vendor,
    );
  },
);

export const selectFormulaForSelectedCell = createSelector(selectUiDatasourceForSelectedCell, (datasource) => {
  if (!datasource) return null;
  if (datasource.type === "range") {
    const activeDatasourceFormula =
      datasource.value.type === "formula" || datasource.value.type === "hiring-plan-formula"
        ? datasource.value.options.formula
        : null;

    return activeDatasourceFormula;
  } else {
    return datasource.value;
  }
});

export const selectIsCellDisabled = createSelector(
  selectSelectedRow,
  selectors.activeCell,
  selectSelectedTemplate,
  selectors.collapsedRows,
  selectVersionLocked,
  selectScenarioId,
  (state: RootState) => state.datasources,
  (selectedRow, activeCell, template, collapsedEntityIds, isVersionLocked, scenarioId, datasourcesState) => {
    const collapsed = collapsedEntityIds.includes(selectedRow?.id || "");
    return (
      !template ||
      isVersionLocked ||
      (activeCell.column === "name" && template?.type === "financial_statement") ||
      !!collapsed ||
      isCellDisabled(selectedRow, activeCell.column ?? null, scenarioId, template, datasourcesState)
    );
  },
);

export const selectRowIdIsBeingDragged = createSelector(
  selectors.draggingFrom,
  (_state: ClientRootState, rowId: string) => rowId,
  (dragging, rowId: string) => dragging === rowId,
);

export const selectIsCellPending = createSelector(
  selectors.pendingDateKeys,
  (_state: ClientRootState, rowId: string) => rowId,
  (_state: ClientRootState, _rowId: string, dateKey: string) => dateKey,
  (pendingDateKeys, rowId, dateKey) =>
    pendingDateKeys.some((item) => item.rowId === rowId && item.dateKeys.includes(dateKey)),
);

export const selectIsFormulaBarFocused = createSelector(
  selectors.cellBeingEdited,
  (cellBeingEdited) => cellBeingEdited?.component === "formulaBar",
);

export const selectFormulaBeingEdited = createSelector(
  selectors.cellBeingEdited,
  (cellBeingEdited) => cellBeingEdited?.formula ?? null,
);

export const selectIsCellBeingEdited = createSelector(
  selectors.cellBeingEdited,
  (_state: ClientRootState, rowId: string) => rowId,
  (_state: ClientRootState, _rowId: string, dateKey: string) => dateKey,
  (cellBeingEdited, rowId, dateKey) => cellBeingEdited?.rowId === rowId && cellBeingEdited.dateKey === dateKey,
);

export interface FlattenedOrderingItemForUi extends FlattenedOrderingItem {
  collapsed: boolean;
  departmentId?: string;
  vendor?: string;
  emptyHeaderRow?: boolean;
  expanded?: boolean;
  id: string;
}

export const selectFlattenedOrderingForUi = createSelector(
  (state: RootState) => state.templateRows,
  (state: RootState) => state.departments,
  selectFlatTemplateOrdering,
  selectFlattenedDepartments,
  globalSelectors.departmentsExpandedRowIds,
  globalSelectors.expandedVendors,
  selectors.collapsedRows,
  selectRowIdToVendorsMapping,
  (state: RootState) => state.sanityChecks,
  selectDepartmentId,
  (
    templateRowsState,
    departmentsState,
    flatOrdering,
    flattenedDepartments,
    rowIdsWithExpandedDepts,
    expandedVendors,
    collapsedRows,
    rowIdToVendorsMapping,
    sanityChecksState,
    globalDepartmentId,
  ) => {
    const result: FlattenedOrderingItemForUi[] = [];
    let prevDepth = 0;
    let prevRowCollapsed = false;

    for (const {rowId, total, depth, hasChildren} of flatOrdering) {
      const row = templateRowsState.entities[rowId];
      if (!row) continue;

      if (prevRowCollapsed && (prevDepth < depth || (total && prevDepth === depth))) continue;

      const collapsed = !total && collapsedRows.includes(rowId);
      const departmentsExpanded = rowIdsWithExpandedDepts.includes(rowId);

      prevDepth = depth;
      prevRowCollapsed = collapsed;

      // No need to display the total row if it's collapsed
      if (collapsed && total) continue;

      const expanded = !total && !collapsed && !hasChildren && departmentsExpanded;

      const rowForecastType = row.type === "account" ? (row.options.forecastType ?? "row") : "row";

      result.push({
        id: nanoid(),
        rowId,
        total,
        collapsed,
        depth,
        hasChildren,
        emptyHeaderRow: rowForecastType !== "row" && !total && departmentsExpanded,
        expanded,
        type: total ? "total" : rowForecastType !== "row" && departmentsExpanded ? "header" : "values",
      });

      // If this is not a parent row AND the departments are expanded, add the departments to the ordering
      if (expanded) {
        let previousDepartmentDepth: number = depth + 1;
        let prevDeptCollapsed = false;
        let totalRowToAdd: null | FlattenedOrderingItemForUi = null;
        const lastDepartmentByDepth: Record<number, DepartmentWithDepth> = {};

        for (const department of flattenedDepartments) {
          const vendorsExpanded = expandedVendors[rowId]?.includes(department.id);
          const departmentDepth = depth + 1 + department.depth;
          const departmentHasChildren = !!departmentsState.idsByParent[department.id]?.length;
          const deptCollapsed = collapsedRows.includes(`${rowId}::${department.id}`);

          if (prevDeptCollapsed && previousDepartmentDepth && previousDepartmentDepth < departmentDepth) continue;

          // Handle total when we move up one depth level
          if (
            !prevDeptCollapsed &&
            previousDepartmentDepth > departmentDepth &&
            lastDepartmentByDepth[departmentDepth]
          ) {
            const totalDepartment = lastDepartmentByDepth[departmentDepth];
            if (totalDepartment) {
              result.push({
                id: nanoid(),
                rowId,
                total: true,
                collapsed: collapsedRows.includes(`${rowId}::${totalDepartment}`),
                depth: departmentDepth,
                hasChildren: totalDepartment.hasChildren,
                departmentId: totalDepartment.id,
                type: "total",
              });
            }
            totalRowToAdd = null;
          }

          if (!deptCollapsed && vendorsExpanded) {
            result.push({
              id: nanoid(),
              rowId,
              total: false,
              collapsed: false,
              depth: departmentDepth,
              hasChildren: department.hasChildren,
              emptyHeaderRow: true,
              departmentId: department.id,
              expanded: true,
              type: "header",
            });
            if (!departmentHasChildren) {
              if (rowIdToVendorsMapping[rowId]) {
                const processedVendors: Set<string> = new Set();
                for (const vendor of rowIdToVendorsMapping[rowId]) {
                  const vendorName = vendor ?? "no_vendor";
                  if (processedVendors.has(vendorName)) continue;
                  processedVendors.add(vendorName);
                  result.push({
                    id: nanoid(),
                    rowId,
                    total: false,
                    collapsed: false,
                    depth: departmentDepth + 1,
                    hasChildren: false,
                    vendor: vendorName,
                    departmentId: department.id,
                    type: "values",
                  });
                }
              } else {
                result.push({
                  id: nanoid(),
                  rowId,
                  total: false,
                  collapsed: false,
                  depth: departmentDepth + 1,
                  hasChildren: departmentHasChildren,
                  vendor: "no_vendor",
                  departmentId: department.id,
                  type: "values",
                });
              }
            }
            if (!department.hasChildren) {
              result.push({
                id: nanoid(),
                rowId,
                total: true,
                collapsed: false,
                depth: departmentDepth,
                hasChildren: department.hasChildren,
                departmentId: department.id,
                type: "total",
              });
            }
          } else {
            result.push({
              id: nanoid(),
              rowId,
              total: false,
              collapsed: deptCollapsed,
              depth: departmentDepth,
              hasChildren: department.hasChildren,
              departmentId: department.id,
              emptyHeaderRow: departmentHasChildren && !deptCollapsed,
              type: departmentHasChildren && !deptCollapsed ? "header" : "values",
            });
          }
          prevDeptCollapsed = deptCollapsed;
          previousDepartmentDepth = departmentDepth;
          lastDepartmentByDepth[departmentDepth] = department;
        }
        if (totalRowToAdd) {
          result.push(totalRowToAdd);
          totalRowToAdd = null;
        }

        // Add the total row to display as well as the sanity check rows
        result.push({rowId, total: true, collapsed: false, depth, hasChildren: false, id: nanoid(), type: "total"});
      }

      if (!globalDepartmentId && (!hasChildren || total)) {
        const sanityCheck = mapEntitiesToIds(
          sanityChecksState.entities,
          sanityChecksState.idsByCheckEntityIdentifier[rowId],
        )[0];

        if (sanityCheck) {
          result.push(
            {
              id: nanoid(),
              rowId,
              total: false,
              collapsed: false,
              depth: depth + 1,
              hasChildren: false,
              type: "sanityCheckSource",
            },
            {
              id: nanoid(),
              rowId,
              total: false,
              collapsed: false,
              depth: depth + 1,
              hasChildren: false,
              type: "sanityCheckDiff",
            },
          );
        }
      }
    }

    return result;
  },
);

export const selectDatasourcesForSelectedRow = createSelector(
  selectScenarioId,
  selectSelectedRow,
  selectors.activeCell,
  (state: RootState) => state.datasources,
  (scenarioId, selectedRow, activeCell, datasourcesState) => {
    return !scenarioId || !selectedRow
      ? []
      : mapEntitiesToIds(
          datasourcesState.entities,
          datasourcesState.idsByProjKey[
            projKeyOmitNulls(selectedRow.id, scenarioId, activeCell.departmentId, activeCell.vendor)
          ],
        );
  },
);
