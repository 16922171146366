import {getDatasourcesForMonth} from "@shared/lib/datasource-utilities";
import {getForecastTypeForRow} from "@shared/lib/row-utilities";
import memoizeOne from "memoize-one";

import {selectUiDatasource, type FlattenedOrderingItemForUi} from "./selectors";

import type {ClientRootState} from "@app/client-store";
import {getValueFromCache} from "@shared/data-functions/cache/cache-utilities";
import type {Template, TemplateRow} from "@shared/types/db";
import type {DatasourcesState} from "@state/entity-adapters";

export function isBlankCell(
  uiOrderingItem: FlattenedOrderingItemForUi,
  dateKey: string,
  scenarioId: string,
  datasourcesState: DatasourcesState,
  row: TemplateRow,
  template: Template,
  departmentId: string | null,
) {
  const forecastType = getForecastTypeForRow(row, departmentId);
  // if (row.name.includes("9123") && !departmentId && dateKey === "2024-04") {
  //   console.log({uiOrderingItem, forecastType});
  // }
  if (
    forecastType === "department" &&
    uiOrderingItem.departmentId &&
    uiOrderingItem.emptyHeaderRow &&
    uiOrderingItem.type === "header" &&
    !uiOrderingItem.total &&
    !uiOrderingItem.collapsed &&
    !uiOrderingItem.hasChildren
  ) {
    const activeDs = getActiveDs(datasourcesState, uiOrderingItem, dateKey, scenarioId, row, template, departmentId);
    return activeDs?.type === "integration";
  }

  if (
    (forecastType === "department" || forecastType === "vendor") &&
    uiOrderingItem.departmentId &&
    uiOrderingItem.emptyHeaderRow &&
    uiOrderingItem.type === "header" &&
    !uiOrderingItem.total &&
    !uiOrderingItem.collapsed
  ) {
    return true;
  }

  if (
    uiOrderingItem.departmentId &&
    uiOrderingItem.emptyHeaderRow &&
    uiOrderingItem.type === "header" &&
    !uiOrderingItem.total &&
    !uiOrderingItem.collapsed
  ) {
    return true;
  }

  if (
    forecastType === "department" &&
    !uiOrderingItem.departmentId &&
    uiOrderingItem.emptyHeaderRow &&
    uiOrderingItem.type === "header" &&
    !uiOrderingItem.total &&
    !uiOrderingItem.collapsed &&
    uiOrderingItem.expanded
  ) {
    return true;
  }

  // When a row-level forecasted row is expanded, the forecast should not be hidden if we're in the top row
  // EXCEPT if this month is coming from an integration datasource
  if (forecastType === "row" && !uiOrderingItem.departmentId && !uiOrderingItem.vendor && uiOrderingItem.expanded) {
    const activeDs = getActiveDs(datasourcesState, uiOrderingItem, dateKey, scenarioId, row, template, departmentId);
    return activeDs?.type === "integration";
  }

  // Same thing for departments
  if (forecastType === "department" && !uiOrderingItem.vendor && !!uiOrderingItem.departmentId) {
    return false;
  }

  if (
    uiOrderingItem.emptyHeaderRow ||
    uiOrderingItem.expanded ||
    uiOrderingItem.vendor ||
    (forecastType !== "department" && uiOrderingItem.departmentId)
  ) {
    const activeDs = getActiveDs(datasourcesState, uiOrderingItem, dateKey, scenarioId, row, template, departmentId);
    return forecastType !== "vendor" ? activeDs?.type !== "integration" : false;
  }

  return false;
}

export const getActiveDs = memoizeOne(
  (
    datasourcesState: DatasourcesState,
    uiOrderingItem: FlattenedOrderingItemForUi,
    dateKey: string,
    scenarioId: string,
    row: TemplateRow,
    template: Template,
    departmentId: string | null,
  ) => {
    const forecastType = getForecastTypeForRow(row, departmentId);
    const rowLevel = getUiRowLevel(uiOrderingItem);
    const isActual = dateKey <= template.options.lastMonthOfActuals;

    if (rowLevel === "row") {
      return getDatasourcesForMonth(
        datasourcesState,
        {rowId: uiOrderingItem.rowId, scenarioId, dateKey},
        template.options,
      )[0];
    } else if (rowLevel === "department") {
      return getDatasourcesForMonth(
        datasourcesState,
        {
          rowId: uiOrderingItem.rowId,
          scenarioId,
          dateKey,
          departmentId: forecastType === "department" && !isActual ? uiOrderingItem.departmentId : null,
        },
        template.options,
      )[0];
    } else {
      return getDatasourcesForMonth(
        datasourcesState,
        {
          rowId: uiOrderingItem.rowId,
          scenarioId,
          dateKey,
          departmentId: forecastType === "vendor" && !isActual ? uiOrderingItem.departmentId : null,
          vendor: forecastType === "vendor" && !isActual ? uiOrderingItem.vendor : null,
        },
        template.options,
      )[0];
    }
  },
);

// function isBlankRowCell(
//   uiOrderingItem: FlattenedOrderingItemForUi,
//   isIntegration: boolean,
//   forecastType: ReturnType<typeof getForecastTypeForRow>,
// ) {
//   if (!uiOrderingItem.expanded) return false;
//   if (uiOrderingItem.collapsed) return false;

//   if (forecastType !== "row" || (forecastType === "row" && uiOrderingItem.expanded && isIntegration)) return true;

//   if (forecastType === "row" && !isIntegration) return false;

//   // if (forecastType !== "row") {
//   //   return !isIntegration;
//   // }

//   // return true;
// }

// function isBlankDeptCell(
//   uiOrderingItem: FlattenedOrderingItemForUi,
//   isIntegration: boolean,
//   forecastType: ReturnType<typeof getForecastTypeForRow>,
//   debug?: boolean,
// ) {
//   if (uiOrderingItem.hasChildren && !uiOrderingItem.total && !uiOrderingItem.collapsed) return true;
//   if (uiOrderingItem.collapsed || uiOrderingItem.total) return false;
//   if (!uiOrderingItem.expanded) {
//     if (forecastType === "row" && !isIntegration) {
//       return true;
//     } else {
//       return false;
//     }
//   }
//   if (uiOrderingItem.expanded && forecastType === "department") {
//     return isIntegration;
//   }
//   if (forecastType !== "department" || (forecastType === "department" && uiOrderingItem.expanded && !isIntegration))
//     return true;
// }

// function isBlankVendorCell(isIntegration: boolean, forecastType: ReturnType<typeof getForecastTypeForRow>) {
//   if (forecastType !== "vendor") {
//     return !isIntegration;
//   }

//   return false;
// }

function getUiRowLevel(uiOrderingItem: FlattenedOrderingItemForUi) {
  if (uiOrderingItem.vendor) return "vendor";
  if (uiOrderingItem.departmentId) return "department";
  return "row";
}

// TODO: make this work for highlighted cells
export function getSelectedCellsData(state: ClientRootState) {
  const {highlightedDateKeys, activeCell} = state.templatesView;

  if (!activeCell?.column) return null;

  if (activeCell.column === "name" && activeCell.rowId) {
    return {
      formulas: [state.templateRows.entities[activeCell.rowId]?.display_name ?? ""],
      values: [state.templateRows.entities[activeCell.rowId]?.display_name ?? ""],
    };
  }

  const dateKeys = highlightedDateKeys?.length ? highlightedDateKeys : [activeCell.column];

  const formulas: string[] = [];
  const values: number[] = [];
  for (const dateKey of dateKeys) {
    const {type: dataType, value: datasource} =
      selectUiDatasource(state, activeCell.rowId ?? "", dateKey ?? "", activeCell.departmentId, activeCell.vendor) ??
      {};
    let formula = "";
    if (dataType === "range" && datasource?.type === "formula") {
      formula = datasource.options.formula ?? "";
    }
    formulas.push(formula);

    const row = state.templateRows.entities[activeCell.rowId ?? ""];
    if (!row) continue;
    const value = getValueFromCache(
      state.transactionItems.valuesByRowIdDateKey,
      row,
      activeCell.departmentId,
      activeCell.vendor,
      state.global.scenarioId!,
      dateKey,
      row.type === "account" && row.options.balance === true,
      false,
    );
    values.push(value ?? 0);
  }

  // const {type: dataType, value: datasource} = selectUiDatasourceForSelectedCell(state) || {};

  // if (dataType !== "range" || datasource?.type === "integration") return;
  // const formula = datasource ? datasource.options.formula : null;

  return {formulas, values};
}

/**
 * This function returns the new highlight cursor cell. It is used as part of the "expand selection" functionality, like Excel does with the shortcut ctrl+shift+arrowLeft or arrowRight
 * Its return value will be used to marked all cells between the active cell and the new highlight cursor cell as highlighted
 *
 * Rules:
 * - If the current highlight cursor cell is empty and the next cell is also empty, it skips all empty cells until it finds a non-empty cell. That cell will now be the new highlight cursor cell
 * - If the current highlight cursor cell is empty and the next cell is non-empty, that next cell becomes the new highlight cursor cell
 * - If the current highlight cursor cell is non-empty and the next cell is empty, it skips all empty cells until it finds a non-empty cell. That cell will now be the new highlight cursor cell
 * - If the current highlight cursor cell is non-empty and the next cell is non-empty, it will skip all non-empty until it hits the last non-empty cell. That last non-empty cell becomes the new highlight cursor cell
 *
 */
export function getNewHighlightCursorCell(
  currentHighlightCursorCell: string,
  direction: "left" | "right",
  highlightDirection: "left" | "right",
  dateKeysForRow: string[],
  valuesForRow: (string | number | null)[],
): string {
  const currentIndex = dateKeysForRow.indexOf(currentHighlightCursorCell);

  if (currentIndex === -1) return currentHighlightCursorCell; // Return the current highlight cursor cell if it's not found in the array

  const directionValue = direction === "right" ? 1 : -1;
  const upperLimit = dateKeysForRow.at(-1) ?? dateKeysForRow[0];
  const lowerLimit = dateKeysForRow[0];

  const isInitialValueEmpty = valuesForRow[currentIndex] === null || valuesForRow[currentIndex] === "";
  const isInitialNextValueEmpty =
    valuesForRow[currentIndex + directionValue] === null || valuesForRow[currentIndex + directionValue] === "";

  for (let i = currentIndex + directionValue; i >= 0 && i < dateKeysForRow.length; i += directionValue) {
    const isCurrentValueEmpty = valuesForRow[i] === null || valuesForRow[i] === "";
    const currentKey = dateKeysForRow[i];
    const isNextValueEmpty = valuesForRow[i + directionValue] === null || valuesForRow[i + directionValue] === "";

    // console.log({
    //   currentKey,
    //   isCurrentValueEmpty,
    //   isNextValueEmpty,
    //   isInitialValueEmpty,
    //   isInitialNextValueEmpty,
    //   direction: directionValue,
    // });

    // If we hit the upper or lower limit, return the current cell
    if ((directionValue === 1 && currentKey === upperLimit) || (directionValue === -1 && currentKey === lowerLimit)) {
      return currentKey;
    }

    if (isInitialValueEmpty) {
      // If the initial value is empty, skip until the first non-empty cell
      if (!isCurrentValueEmpty) {
        return currentKey;
      } else {
        continue;
      }
    } else {
      // If the initial value is not empty, skip until the last non-empty cell
      if (isCurrentValueEmpty) {
        if (!isInitialNextValueEmpty || !isNextValueEmpty) {
          const index = highlightDirection === direction ? i + directionValue : i;
          return dateKeysForRow[i + directionValue];
        } else {
          continue;
        }
      } else {
        if (isNextValueEmpty) {
          return dateKeysForRow[i];
        } else {
          continue;
        }
      }
    }
  }

  return direction === "right" ? upperLimit : lowerLimit;
}
